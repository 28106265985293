
.loadingCircle {
    color: black;
}

.circleContainer {
    margin-top: 200px;
    margin-bottom: 200px;
}

.MuiButton-fullWidth {
    width: 100%;
}

.MuiButton-root {
    color: rgba(0, 0, 0, 0.87);
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}

.MuiButtonBase-root {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
        margin-top: 0px;
        margin-bottom: 0px;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    -moz-user-select: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.MuiButton-contained {
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    background-color: #e0e0e0;
}

.Button{
    color: white !important;
    height: 45px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #00378b !important;
}

.successMessage {
    color: #249225;
    padding: 25px;
    margin-top: -30px !important;
}

.errMessage {
    color: #ff2400;
    padding: 25px;
    margin-top: -30px !important;
}

.formContainer {
    min-width: 35%;
    height: 100%;
    margin-top: 75px;
    margin-bottom: 75px;
}

.parameterContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 20px;
    margin-bottom: 20px;

}

.title {
    color: #00378b !important;
    margin-top: -40px !important;
    margin-bottom: 50px !important;
}

.parameterName {
    font-size: 20px;
    font-weight: 600;
}

.subtopic-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}