
.Card {
    padding-block: 20px;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-evenly;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Primary {
    display: flex;
    color: #404040;
}

.loadingCircle {
    color: black;
}

.circleContainer {
    margin-top: 200px;
}

.footer {
    position: absolute;
    bottom: 4px;
    height: 2em;
    left: 50%;
    translate: -50%;
}